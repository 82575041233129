body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  font-family: sans-serif
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Poppins';
    font-style: bold;
    font-weight: 800;
    /* font-display: swap; */
    src: url('../src/fonts/Poppins-Black.ttf') format('ttf');
  }
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  /* background-image: url('./assets/bg-dark.jpg'); */
  background-repeat: repeat;
  background-color: rgb(var(--background-start-rgb));
}

.app {
  @apply relative z-10 flex flex-col w-full mx-auto;
}

.exerciceContentBlock {
  @apply relative flex flex-col w-[100%] flex-wrap items-center justify-evenly py-5 rounded-lg
}

.exerciceLongContentDisplay {
  @apply flex flex-col text-ellipsis text-wrap break-words justify-center text-lg md:text-3xl w-[95%] h-auto my-2 px-4 py-3 rounded-xl bg-gray-800
}

.exerciceShortContentDisplay {
  @apply flex flex-col items-center text-ellipsis break-words justify-center text-xl w-[150px] h-[150px] my-4 rounded-xl bg-gray-800 px-1
}

.exerciceTitle {
  @apply flex-1 text-center text-xl font-bold text-white
}

.exerciceButton {
  @apply flex flex-col items-center justify-center px-3 my-2 md:w-1/2 md:mx-auto uppercase bg-primary text-white rounded font-bold cursor-pointer
}

.exerciceButtonLanguageContainer {
  @apply flex flex-row
}

.exerciceButtonLanguage {
  @apply flex flex-row items-center justify-center bg-light text-black p-2 m-2 rounded-md shadow-lg
}

.exerciceAnswerMessage {
  @apply text-white font-bold px-5 py-3 rounded-lg shadow-xl uppercase
}

.levelSelectButton {
  @apply flex flex-1 py-2 items-center justify-center bg-light text-black transition-all duration-300 ease-in-out cursor-pointer
}

.columnDisplayButton {
  @apply flex flex-1 py-2 items-center justify-center bg-fourth text-white transition-all duration-300 ease-in-out cursor-pointer
}

.readingChoiceButton {
  @apply flex flex-1 py-2 items-center justify-center bg-dark text-white
}

.title {
  @apply text-2xl text-gold text-center font-bold mb-4
}

.dropdown {
  @apply fixed z-50 top-0 left-0 w-full h-[100dvh] p-5 bg-white flex flex-col gap-2 justify-center items-center;
}

.overlay {
  @apply absolute z-40 top-0 left-0 w-full min-h-[400dvh] bg-black opacity-70
}

.dropdown_link {
  @apply text-3xl text-dark-purple hover:text-primary my-3 font-medium;
}

.dropdown_close {
  @apply absolute top-3 right-3 p-3 text-3xl text-primary;
}

.home-arrow {
  animation: arrowAnimation 2s infinite;
}

.home-button {
  @apply flex flex-col items-center justify-center flex-1 mx-2 md:mx-5 my-5 md:my-10 px-5 md:py-5 border-primary border-4 shadow-2xl shadow-dark rounded-xl bg-black transition-all duration-300 ease-in-out
}

.home-button:hover {
  @apply translate-y-2 bg-primary text-white transition-all duration-300 ease-in-out
}

.home-button-text {
  @apply font-bold text-2xl mb-2 uppercase
}

.text-shadow {
  text-shadow: 1px 1px 3px rgb(93, 93, 93);
}

.bg-animation {
  animation: bgAnimation 7s infinite;
}

.divider-shadow {
  box-shadow: 0px -10px 80px 0px rgba(0, 0, 0, 0.5);
}


.hero {
  /* padding: 200px 0 350px; */
  padding: 0px;
}

.separator {
  bottom: 0px;
  left: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 30;
}

.second-section,
.sub-section {
  /* padding: 100px 0 200px;
  position: relative;
  z-index: 10; */
  @apply relative z-40 pt-[100px] pb-[200px]
}

.activities-card {
  @apply flex flex-col w-[80%] mx-auto md:w-1/3 lg:w-1/4 px-3 py-2 bg-white rounded-xl text-center justify-center items-center transition-all duration-300 shadow-xl
}

.activities-card:hover {
  @apply translate-y-1
}

.activities-card-image {
  @apply w-1/6 md:w-1/4 mb-3
}

.activities-card-title {
  @apply text-xl uppercase font-bold text-primary
}

.activities-card-text {
  @apply text-start md:text-center text-sm my-3
}

@media screen and (max-width: 767px) {
  .hero {
    padding: 0;
    padding-bottom: 100px;
  }

  .separator {
    bottom: -110px;
    /* z-index: 50; */

    .separator-svg {
      left: -20%;
      position: relative;
      transform: rotate(15deg);
      width: 140%;
    }
  }

  .second-section,
  .sub-section {
    padding: 0;
    padding-bottom: 200px;
    position: relative;
    /* z-index: 50; */
  }
}





@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@keyframes arrowAnimation {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(5px);
  }

  70% {
    transform: translateY(-1px);
  }

  80% {
    transform: translateY(2px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes bgAnimation {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}



/******* NEON EFFECT ON TEXT  */
/* margin: 0;
color: #fff;
text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #ff00de, 0 0 35px #ff00de, 0 0 40px #ff00de; */